.trustedby_container {
  padding: 20px 64px;
}
.trustedby_container > .container-fluid {
  padding: 0;
  max-width: 1400px;
  margin: 0 auto;
}
.trustedby_container {
  padding: 65px 64px;
}
.trustedby_heading {
  text-align: center;
  font: 500 28px/0px "Poppins";
  letter-spacing: 1.82px;
  color: #2f2233;
  margin-bottom: 54px;
}
.trustedby_list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.trustedby_list_item {
  padding: 0 20px;
}
.trustedby_list_item img {
  max-width: 140px;
  margin: 0 auto;
}
.trustedby_list :global(.slick-prev:before),
.trustedby_list :global(.slick-next:before) {
  color: black;
  zoom: 150%;
}
@media (max-width: 767px) {
  .trustedby_list :global(.slick-prev:before),
  .trustedby_list :global(.slick-next:before) {
    zoom: 200%;
  }
  .trustedby_list :global(.slick-prev){
    left: -50px;
  }
  .trustedby_list :global(button){
    width: 40px;
    height: 40px;
    margin-right: -15px;
  }
  .trustedby_container {
    padding: 25px 64px;
  }
}
@media (max-width: 426px) {
  .trustedby_list :global(.slick-prev:before),
  .trustedby_list :global(.slick-next:before) {
    zoom: 200%;
  }
  .trustedby_list :global(.slick-prev){
    left: -50px;
  }
  .trustedby_list :global(button){
    width: 40px;
    height: 40px;
    margin-right: -15px;
  }
  .trustedby_container {
    padding: 25px 64px;
  }
}
