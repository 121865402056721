.footernav_container{
    padding: 0 64px;
}
.footernav_container .container-fluid{
    max-width: 1400px;
    margin: 0 auto;
}
.footernav_section{
    padding: 64px 0px 32px;
}
.footernav_heading{
    font: 500 28px/36px 'Poppins';
    color: #2F2233;
    margin-bottom: 16px;
    min-height: 36px;
}
.footernav_heading_browse{
    font: 500 28px/36px 'Poppins';
    color: #2F2233;
    margin-bottom: 16px;
    min-height: 36px;
   
}
.footernav_heading_text{
    font: normal 16px/20px "Poppins";
    color: #645767;
    margin-bottom: 16px;
    cursor: pointer;
}
.footernav_heading_text:hover{
    color: #29a6ed;
    text-decoration: underline;
}
.footernav_list,
.footernav_list_social,
.terms_link{
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 21px;
}
.footernav_list_item{
    font: normal 16px/20px 'Poppins';
    color: #645767;
    margin-bottom: 16px;
    cursor: pointer;
}
.footernav_list_item_custom{
    font: normal 16px/20px 'Poppins';
    color: #645767;
    margin-bottom: 16px;
}
.footernav_list_item_custom a:hover{
    text-decoration: underline;
    color:dodgerblue
}
.footernav_list_section{
    margin-bottom: 32px;
}
.footernav_list_heading{
    font: normal 16px/20px 'Poppins';
    color: #645767;
    margin-bottom: 8px;
}
.footernav_list_phone{
    font: normal 18px/24px 'Poppins';
    color: #2F2233;
    letter-spacing: 1.4px;
    margin-bottom: 0;
    text-decoration: underline;
}
.footernav_list_social{
    margin: 0 -9px;
}
.footernav_list_social li{
    margin: 0 9px;
}
.copyright_section{
    padding: 32px 0;
    border-top: 1px solid #707070;
}
.copyright_section_text{
    font: normal 14px/18px 'Poppins';
    color: #645767;
    margin-bottom: 0;
}
.terms_link{
    margin: 0 -10px;
}
.terms_link_item {
    position: relative;
}
.terms_link_item a{
    font: normal 16px/20px 'Poppins';
    color: #645767;
    margin: 0 10px;
}
.terms_link_item::after{
    content: '';
    position: absolute;
    width: 1px;
    height: 70%;
    top: 50%;
    transform: translate(0, -50%);
    right: 0;
    background: #645767;
}
.terms_link_item:last-child::after{
    content: none;
}

@media (max-width: 767px){
    .footernav_container{
        padding: 0 26px;
    }
    .footernav_container .container-fluid{
        padding: 0;
    }
    .footernav_section{
        padding: 28px 0px 32px;
    }
    .footernav_heading .footernav_heading_browse{
        font: 500 20px/23px 'Poppins';
        letter-spacing: 1.4px;
        margin-bottom: 21px;
        min-height: 28px;
    }
    .footernav_list_section:last-child{
        margin-bottom: 0;
    }
}