.testimonial_container {
  padding: 64px 64px 72px;
  background-color: #f9f9f9;
  background-size: cover;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.testimonial_container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30%;
  min-height: 50px;
  background-color: #ffffff;
}
.testimonial_container > .conatiner_fluid {
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  z-index: 3;
}
.testimonial_container_bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* Remove static loading of the image */
  /* background-image: url("/assets/images/testimonial_bg.svg"); */
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  transform: scaleX(2);
  z-index: 2;
}

.search_result_page .testimonial_container {
  background-color: #ffffff;
}
.search_result_page .testimonial_container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: #f9f9f9;
  z-index: 1;
}
.search_result_page .trustedby_container,
.search_result_page .testimonial_container::before {
  background-color: #FFFFFF;
}

.testimonial_heading {
  font: 500 28px/34px "Poppins";
  color: #ffffff;
  margin-top: 16px;
  margin-bottom: 64px;
  text-transform: uppercase;
}
.testimonial_slider {
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;
}
.testimonial_slide_content {
  padding: 40px 70px;
  background-color: #ffffff;
  border-radius: 138px;
  height: 290px;
}
.testimonial_slide_content_para {
  font: normal 16px/27px "Poppins";
  color: #645767;
  margin-bottom: 21px;
}
.testimonial_slide_content_imageheading {
  font: 600 normal 16px/27px "Poppins";
  color: #1e112c;
  margin-bottom: 10;
  margin-top: 0px;
}
.testimonial_slide_content_heading {
  font: 600 24px/34px "Poppins";
  color: #1e112c;
  margin-bottom: 0;
}
.testimonial_slider :global(.slick-arrow) {
  width: 48px;
  height: 48px;
  top: auto;
  bottom: 80px;
}
.testimonial_slider :global(.slick-arrow)::before {
  content: "";
  background-image: url("/assets/images/testimonial_arrow.svg");
  background-position: center;
  transform: rotate(0) translate(0);
  width: 48px;
  height: 48px;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s linear;
}
.testimonial_slider :global(.slick-arrow){
  opacity: 0.5;
 
}
.testimonial_slider :global(.slick-arrow):hover {
  opacity: 1;
}
.testimonial_slider :global(.slick-prev) {
  left: -92px;
  transform: rotate(180deg) translate(0);
}
.testimonial_slider :global(.slick-next) {
  transform: rotate(0) translate(0);
  right: -92px;
}
.testimonial_slide_figure {
  margin-bottom: 0;
}
.testimonial_slide_figure img {
  max-width: 100%;
  margin: 0 auto;
  object-fit: fill;
}
.testimonial_slider :global(.slick-dots) {
  bottom: 25px;
}
.testimonial_slider :global(.slick-dots) li {
  position: relative;
  display: inline-block;
  width: 5px;
  height: 5px;
}
.testimonial_slider :global(.slick-dots) li button {
  width: 5px;
  height: 5px;
  color: rgba(30, 18, 42, 0.24);
  border: 1px solid transparent;
  padding: 0;
}
.testimonial_slider :global(.slick-dots) li button.slick-active {
  color: rgba(30, 18, 42);
  border: 1px solid rgba(30, 18, 42);
}
@media (max-width: 1300px) {
  .testimonial_slider :global(.slick-next) {
    right: -55px;
  }
  .testimonial_slider :global(.slick-prev) {
    left: -55px;
  }
}
@media (max-width: 769px) {
  .testimonial_container {
    padding: 32px 26px;
  }
  .testimonial_slide_content_imageheading {
    font: 600 normal 16px/27px "Poppins";
    color: #1e112c;
    margin-bottom: 10;
    margin-top: 0px;
  }
  .testimonial_container_bg {
    transform: scaleX(2.5);
  }
  .testimonial_slide_content {
    width: 100%;
    height: 0;
    padding-bottom: 89%;
   
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  /* .testimonial_slider .slick-next,
  .testimonial_slider .slick-prev{
    display: none !important;
  } */
  .testimonial_slide_content > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 0 20px;
  }
  .testimonial_slide_figure {
    margin-bottom: -25%;
  }
  .testimonial_slide_figure img {
    object-fit: contain;
  }
  .testimonial_heading {
    font: 500 17px/24px "Poppins";
    letter-spacing: 1.19px;
    margin-bottom: 27px;
  }
  .testimonial_heading_section > img {
    width: 46px;
  }
}
@media (max-width: 426px) {
  .testimonial_slide_content {
    padding-bottom: 82%;
    padding-top: 60px !important;
    margin-top: 120px ;
    overflow: hidden;
  }
  .testimonial_slide_content_imageheading {
    font: 600 normal 12px/27px "Poppins";
    color: #1e112c;
    margin-bottom: 10;
    margin-top: 0px;
  }
  
  .testimonial_slide_content_para {
    font: normal 12px/18px "Poppins";
    margin-bottom: 1.5px;
  }
  .testimonial_slide_content_heading {
    font: 600 14px/20px "Poppins";
  }
  .testimonial_slide_figure {
    margin-bottom: -60%;
  }
  .testimonial_slide_figure img{
    height: auto;
    object-position: top;
    padding-left: 25px;
  }
}
@media (max-width: 376px) {

  .testimonial_slide_figure {
    margin-bottom: -71%;
  }
  .testimonial_slide_content {
    padding-bottom: 82%;
    padding-top: 60px !important;
    margin-top: 159px ;
    overflow: hidden;
  }
}
@media (max-width: 320px) {

  .testimonial_slide_figure {
    margin-bottom: -84%;
  }
}
