.top_cities_container {
  background-color: #34383e; /* fallback color */
  background-size: cover;
  padding: 137px 68px 303px;
  overflow: hidden;
  position: relative;
}
/* Optionally, add a placeholder background or a transition */
.top_cities_container.lazy_bg {
  background-image: none;
  transition: background-image 0.5s ease-in;
}

.testimonial_container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30%;
  min-height: 50px;
  background-color: #202430;
}
.top_cities_container :global(.slick-slide.slick-active.slick-current) {
  z-index: 9;
}
.top_cities_section {
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
}
.top_cities_heading {
  text-transform: uppercase;
  text-align: center;
  font: 500 28px/34px "Poppins";
  letter-spacing: 1.96px;
  color: #ffffff;
  margin-bottom: 131px;
}
.top_cities_main {
  z-index: 2;
}
.top_cities_main_slide {
  width: 547px;
  height: 547px;
  border-radius: 50%;
  border: 3px solid #29a6ed;
  background: rgba(255, 255, 255, 5%);
  /* background-image: url("/assets/images/top_cities_world.png");  Remove this line */
  margin: 0 auto;
  background-size: cover;
  background-position: center;
}

.top_cities_main_slide::before,
.top_cities_main_slide::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  width: 130%;
  height: 130%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  background: rgba(255, 255, 255, 2%);
}
.top_cities_main_slide > * {
  z-index: 1;
}
.top_cities_main_slide::after {
  width: 190%;
  height: 190%;
  border: 1px solid #29a6ed;
  background: rgba(255, 255, 255, 0);
}
.top_cities_main::before {
  content: "";
  width: 120%;
  height: 120%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: 2px solid #29a6ed;
}
.top_cities_main_slide_section {
  width: 547px;
  height: 547px;
}
.top_cities_main_slide_heading {
  text-align: center;
  font: 600 38px/46px "Poppins";
  letter-spacing: 0px;
  color: #ffffff;
  max-width: 390px;
  min-height: 46px;
  max-height: 46px;
  margin: 0 auto 55px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.top_cities_main_slide_para {
  text-align: center;
  font: normal 16px/30px "Poppins";
  letter-spacing: 0px;
  color: #b4a9c0;
  max-width: 390px;
  min-height: 90px;
  max-height: 90px;
  margin: 0 auto 58px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.top_cities_main_slide_icon {
  width: 99px;
  height: 99px;
  background-color: #29a6ed !important;
  box-shadow: 0px 15px 50px #29a6ed45 !important;
  border-radius: 50%;
  margin-bottom: 0;
  overflow: hidden;
  position: relative;
}
.top_cities_main_slide_icon figcaption {
  transition: 0.5s all linear;
  display: inline-block;
  font: 500 28px/34px "Poppins";
  letter-spacing: 1.96px;
  color: #ffffff;
  text-transform: uppercase;
  position: absolute;
}
.top_cities_main_slide_icon:hover figcaption {
  
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s linear;
}


.top_cities_main_slide_icon img {
  transform: translate(0, 120px);
  transition: all 0.4s linear;
}

.top_cities_main_slide_icon:hover img {
  display: inline-block;
  transform: scale(1) !important;
  /* animation-name: example;
  animation-duration: 0.5s;
  transform: translate(0, 120px);
  transition: all 0.4s linear; */
}
/* @keyframes example {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, -120px);
  }
} */
.top_cities_pic {
  width: 646px;
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 91%;
  transform: translate(0, calc(-50% + 84px));
}
.top_cities_pic .top_cities_pic_slide,
.top_cities_pic .top_cities_pic_slide :global(.slick-list) {
  height: 394px;
}
.top_cities_pic_slide_img {
  margin: 0 auto;
  height: 394px;
  width: 310px;
  display: inline-flex;
  align-items: flex-end;
}
.top_cities_pic_slide_img img {
  border-radius: 50%;
  height: 310px;
  transition: all 0.3s ease-out;
}
.top_cities_pic_slide_img > span {
  overflow: visible !important;
}

.top_cities_pic :global(.slick-slide) .top_cities_pic_slide_img img {
  transform: translate(0, -84px);
}
.top_cities_pic
  :global(.slick-slide.slick-active:not(.slick-current))
  .top_cities_pic_slide_img
  img {
  transform: translate(0, 0);
}
.top_cities_pic
  :global(.slick-slide.slick-active:not(.slick-current))
  + :global(.slick-slide)
  .top_cities_pic_slide_img
  img {
  transform: translate(0, +84px);
}
.top_cities_main_slide :global(.slick-arrow::before) {
  content: none;
}
.top_cities_main_slide :global(.slick-arrow) {
  text-align: center;
  font: normal 14px/16px "Poppins";
  letter-spacing: 0px;
  color: #29a6ed;
  width: auto;
  height: auto;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  bottom: -88px;
  top: auto;
  opacity: 0.6;
  transition: all 0.3s linear;
}
.top_cities_main_slide :global(.slick-arrow:hover) {
  opacity: 1;
}
.top_cities_main_slide :global(.slick-arrow) span {
  text-align: center;
  font: normal 14px/16px "Poppins";
  letter-spacing: 0px;
  color: #29a6ed;
  margin-top: 8px;
  transform: none;
}
.top_cities_main_slide :global(.slick-arrow.slick-prev) {
  left: -41px;
}
.top_cities_main_slide :global(.slick-arrow.slick-prev) img {
  transform: rotate(180deg);
}
.top_cities_main_slide :global(.slick-arrow.slick-next) {
  right: -41px;
}
@media (max-width: 991px) {
  .top_cities_container {
    padding: 68px 26px 68px;
  }
  .top_cities_heading {
    font: 500 17px/24px "Poppins";
    letter-spacing: 1.19px;
    margin-bottom: 125px;
  }
  .top_cities_main_slide::before,
  .top_cities_main_slide::after,
  .top_cities_main::before {
    content: none;
  }
  .top_cities_main {
    height: 456px;
    width: 100%;
  }
  .top_cities_main_slide,
  .top_cities_main_slide_section {
    width: 456px;
    height: 456px;
  }
  .top_cities_main_slide_heading {
    font: 600 24px/32px "Poppins";
    letter-spacing: 0px;
    margin-bottom: 10px;
    margin-top: 135px;
  }
  .top_cities_main_slide_para {
    font: normal 13px/24px "Poppins";
    letter-spacing: 0px;
    margin-bottom: 10px;
    max-width: 293px;
  }
  .top_cities_main_slide_icon {
    width: 81px;
    height: 81px;
  }
  .top_cities_main_slide_icon figcaption {
    display: none;
  }
  .top_cities_main_slide_icon img {
    font: 600 21px/19px "Poppins";
    letter-spacing: 0px;
    transform: scale(1);
  }
  .top_cities_main_slide :global(.slick-arrow) {
    top: -72px;
    bottom: auto;
  }
  .top_cities_main_slide :global(.slick-arrow) img {
    width: 10px;
  }
  .top_cities_main_slide :global(.slick-arrow) span {
    font: normal 12px/14px "Poppins";
  }
  .top_cities_pic .top_cities_pic_slide,
  .top_cities_pic .top_cities_pic_slide :global(.slick-list),
  .top_cities_pic_slide_img,
  .top_cities_pic_slide_img img {
    height: 214px;
    width: 214px;
  }
  .top_cities_pic :global(.slick-slide.slick-current) .top_cities_pic_slide_img img {
    transform: translate(0, 0);
  }
  .top_cities_pic {
    width: 214px;
    top: 0;
    left: 50%;
    transform: translate(-50%, -25%);
  }
  .top_cities_main_slide :global(.slick-arrow.slick-prev) {
    left: 80px;
  }
  .top_cities_main_slide :global(.slick-arrow.slick-next) {
    right: 80px;
  }
  .top_cities_main_slide {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
