.featured_video_container{
    padding: 108px 64px 72px;
    position: relative;
    overflow: hidden;
}
.featured_video_container .container_fluid{
    padding: 0;
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
}
.featured_video_container .container_fluid .row > *:not(.featured_video_thumpnail),
.featured_video_container .container_fluid .row > *:not(.featured_video_bg){
    position: relative;
    z-index: 2;
}
.featured_video_bg{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #1a2632;
    border-radius: 0 0 50% 50%/ 0 0 100% 100%;
    transform: scaleX(2.5);
    z-index: 1;
}
.featured_video_content_heading{
    font: 500 28px/36px 'Poppins';
    color: #ffffff;
    margin-bottom: 15px;
    text-transform: uppercase;
}
.featured_video_content_title{
    font: 500 42px/62px 'Poppins';
    color: #ffffff;
    margin-bottom: 12px;
}
.featured_video_content_para{
    font: normal 16px/30px 'Poppins';
    color: #B4A9C0;
    margin-bottom: 29px;
}
.featured_video_content_para span,.featured_video_content_para p{
    font: normal 16px/30px 'Poppins';
    color: #B4A9C0;
}
.featured_video_content_btn{
    height: 53px;
    min-width: 195px;
    background: #29A6ED;
    font: 600 16px/30px 'Poppins';
    color: #ffffff;
    border: none;
    padding-left: 25px;
    border-radius: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    display: block;
    margin: auto;
}
.featured_video_content_btn_icon{
    margin-right: 20px;
    position: absolute;
    left: 50px;
    top: 17px;
    transition: all 0.5s linear;
    opacity: 0;
    visibility: hidden;
    transform: rotate(360deg);
}
.featured_video_content_btn_icon.show{
    opacity: 1;
    visibility: visible;
    transform: rotate(0deg);
}
.featured_video_player iframe{
    width: 100%;
    max-width: 620px;
    height: 400px; 
    border-radius: 18px; 
}
.featured_video_slider{
    margin-top: 72px !important;
    margin-bottom: 32px !important;
}
.featured_video_slider :global(.slick-slider){
    margin: 0 -17px;
}
.featured_video_slider_figure{
    padding: 0 17px;
    position: relative;
    border-radius: 15px;
    margin-bottom: 0;
}
.featured_video_slider_figure img{
    width: 100%;
    border-radius: 18px;
    object-fit: cover;
    cursor: pointer;
}
.featured_video_slider_figure figcaption{
    font: normal 15px/20px 'Poppins';
    color: #ffffff;
    position: absolute;
    bottom: 22px;
    left: 31px;
}
.featured_video_slider :global(.slick-arrow)::before{
    content: none;
}
.featured_video_slider :global(.slick-arrow){
    background-image: url('/assets/images/slider_icon.svg');
    width: 10px;
    height: 15px;
    background-position: center;
    transform: rotate(0);
}
.featured_video_slider :global(.slick-arrow){
    transform: rotate(180deg);
    zoom: 130%;
}
.featured_video_slider :global(.slick-arrow){
    right: -16px;
}
.featured_video_slider :global(.slick-prev):hover, 
.featured_video_slider :global(.slick-prev):focus, 
.featured_video_slider :global(.slick-next):hover, 
.featured_video_slider :global(.slick-next):focus{
    background-image: url('/assets/images/slider_icon.svg');
}
.featured_video_slider :global(.slick-next){
    transform: rotate(0deg);
}
.more_band_btn{
    border: 1px solid #29A6ED !important;
    border-radius: 41px !important;
    min-width: 203px;
    height: 54px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    font: 500 16px/20px 'Poppins';
    color: #29A6ED !important;
    background: transparent !important;
    margin: 32px auto 0;
}
.more_band_btn span{
    font: 500 16px/20px 'Poppins';
    color: #29A6ED;
}
.featured_video_thumpnail {
    width: 75%;
    height: 75%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    margin-bottom: 0;
    box-shadow: inset 51px 0 200px 200px #1a2632;
    background-image: radial-gradient(rgba(26, 38, 50, 0%) 50%,  #1A2632 100%),url("/assets/images/videobg.png");
    background-position: center;
    background-size: cover;
}
@media( max-width: 767px){
    .featured_video_container{
        padding: 53px 42px 35px;
    }
    .featured_video_thumpnail{
        height: 30%;
        width: 100%;
        top: 50%;
        transform: translate(0, -50%);
        box-shadow: inset 0 0 100px 100px #1a2632;
    }
    .featured_video_slider{
        margin-bottom: 0 !important;
        margin-top: 26px !important;
    }
    .featured_video_bg{
        transform: scaleX(4.5);
    }
    .featured_video_slider :global(.slick-next){
        right: -10px;
        transform: rotate(0deg);
    }
    .featured_video_slider :global(.slick-prev) {
        left: -10px;  
    }
    .featured_video_content_heading{
        font: 500 17px/24px 'Poppins';
        letter-spacing: 1.19px;
        text-align: center;
    }
    .featured_video_content_title{    
        font: 500 22px/40px 'Poppins';
        letter-spacing: 0px;
    }
    .featured_video_content_para{
        font: normal 10px/20px 'Poppins';
        letter-spacing: 0px;
        margin-bottom: 0;
    }
    .featured_video_content_btn{
        display: none;
    }
    .more_band_btn {
        min-width: 123px;
        height: 33px !important;
    }
    .more_band_btn span{
        font: 500 10px/12px 'Poppins';
        letter-spacing: 0px;
    }
    .more_band_btn{
        border: 1px solid #29A6ED !important;
        border-radius: 41px !important;
        min-width: 203px;
        height: 54px !important;
        display: flex !important;
        align-items: center;
        justify-content: center;
        font: 500 16px/20px 'Poppins';
        color: #29A6ED !important;
        background: transparent !important;
        margin: 32px auto 0;
    }
    .more_band_btn span{
        font: 500 16px/20px 'Poppins';
        color: #29A6ED;
    }
}
@media( max-width: 480px){
    .featured_video_player div:first-child{
        height: 220px!important;
    }
}
@media( max-width: 426px){
    .more_band_btn{
        border: 1px solid #29A6ED !important;
        border-radius: 41px !important;
        min-width: 209px;
        height: 57px !important;
        display: flex !important;
        align-items: center;
        justify-content: center;
        font: 500 16px/20px 'Poppins';
        color: #29A6ED !important;
        background: transparent !important;
        margin: 32px auto 0;
    }
    .more_band_btn span{
        font: 500 17px/20px 'Poppins';
        color: #29A6ED;
    }

}
@media( max-width: 376px){
    .more_band_btn{
        border: 1px solid #29A6ED !important;
        border-radius: 41px !important;
        min-width: 209px;
        height: 57px !important;
        display: flex !important;
        align-items: center;
        justify-content: center;
        font: 500 16px/20px 'Poppins';
        color: #29A6ED !important;
        background: transparent !important;
        margin: 32px auto 0;
    }
    .more_band_btn span{
        font: 500 17px/20px 'Poppins';
        color: #29A6ED;
    }
    
}