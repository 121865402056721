.recent_cities_container {
  padding: 0 64px;
  text-align: center;
}
.recent_cities_container .container_fluid {
  max-width: 1400px;
  padding: 64px 0;
  margin: 0 auto;
  border-bottom: 1px solid #e1e1e1;
}
.recent_cities_heading {
  font: 500 28px/34px "Poppins";
  color: #2f2233;
  margin-bottom: 23px;
  text-transform: uppercase;
  letter-spacing: 1.96px;
}
.recent_cities_list {
  padding: 0;
  margin: 0 -15px 17px;
  list-style: none;
}
.recent_cities_list_item {
  font: normal 16px/20px "Poppins";
  color: #2f2233;
  margin-bottom: 10px;
  padding: 0 15px;
  border-right: 1px solid #2f2233;
  cursor: pointer;
}
.recent_cities_list_items {
  color: #2f2233;

}
.recent_cities_list_items:hover {
  color: #29a6ed;
  text-decoration: underline;

}
.recent_cities_list_item:last-child {
  border-right: none;
}
.recent_cities_btn {
  font: 500 16px/20px "Poppins";
  color: #29a6ed;
  margin-bottom: 0;
  text-decoration: underline;
}
@media (max-width: 767px) {
  .recent_cities_container {
    padding: 0 26px;
  }
  .recent_cities_container .container_fluid {
    padding: 32px 0;
  }
  .recent_cities_heading {
    font: 500 20px/23px "Poppins";
    letter-spacing: 1.4px;
    margin-bottom: 21px;
  }
  .recent_cities_list {
    margin-bottom: 21px;
  }
  .recent_cities_list_item {
    font: normal 16px/18px "Poppins";
    letter-spacing: 0px;
    color: #645767;
    margin-bottom: 15px;
  }
}
