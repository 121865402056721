.getintouch_container {
  padding: 72px 64px 64px;
  background-color: #f9f9f9;
}
.getintouch_section_content_heading,
.getintouch_section_content_heading b {
  font: 100 42px/56px "Poppins";
  color: #2f2233;
  margin-bottom: 12px;
}
.getintouch_section_content_heading b {
  font-weight: 500;
}
.getintouch_section_content_para {
  font: normal 24px/30px "Poppins";
  color: #2f2233;
  margin-bottom: 0;
}
.getintouch_section_btn {
  font: 600 16px/30px 'Poppins';
  color: #ffffff;
  background-color: #29a6ed;
  border-radius: 48px;
  min-width: 273px;
  height: 54px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  padding: 0 35px;
  /* text-transform: uppercase; */
}
@media (max-width: 991px) {
  .getintouch_section_content_para {
    margin-bottom: 28px;
  }
}
@media (max-width: 767px) {
  .getintouch_container {
    padding: 32px 26px 32px;
  }
  .getintouch_section_content_heading,
  .getintouch_section_content_heading b {
    font: normal 24px/29px "Poppins";
    margin-bottom: 7px;
  }
  .getintouch_section_content_heading b {
    font-weight: 500;
  }
  .getintouch_section_content_para {
    font: normal 12px/17px "Poppins";
    letter-spacing: 0.84px;
    margin-bottom: 28px;
  }
  .getintouch_section_btn {
    font: 500 16px/20px "Poppins";
    min-width: 181px;
    height: 51px;
    padding: 0 22px;
  }
  .getintouch_section_btn img {
    width: 17px;
  }
}
