.aboutus_container{
    padding-bottom: 64px;
}
.aboutus_container > *{
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 30px !important;
}
.aboutus_container > * > .row{
    margin: 0 -30px;
}
.aboutus_container > * > .row > *{
    padding: 0 30px;
}
.aboutus_content{
    padding-right: 128px !important;
}
.aboutus_content_section{
    margin-bottom: 16px;
    position: relative;
}
.aboutus_content_section_border::before {
    content: "";
    width: 100%;
    position: absolute;
    height: 1px;
    background: #707070;
    top: 19px;
    z-index: 1;
}
.aboutus_image figure{
    position: sticky;
    top: 115px;
    width: 100%;
    margin-bottom: 0;
    overflow: hidden;
}
.aboutus_image figure img{
    width: 100%;
}
.aboutus_image figure .aboutus_image_photo{
    position: absolute;
    width: 45%;
    min-width: 45% !important;
    max-width: 100% !important;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
}
.aboutus_image figure .aboutus_image_photo_two{
    right: -50%;
}
.aboutus_content_heading{
    font: 500 26px/36px 'Poppins';
    letter-spacing: 1.89px;
    color: #2F2233;
    text-transform: uppercase;
    margin-bottom: 13px;
}
.aboutus_content_section_heading{
    font: 500 21px/36px 'Poppins';
    letter-spacing: 1.47px;
    color: #645767;
    margin-bottom: 16px;
    display: inline-block;
    padding-right: 32px;
    background-color: #ffffff;
    position: relative;
    z-index: 2;
}
.aboutus_content_title{
    font: 600 42px/60px 'Poppins';
    color: #2F2233;
    margin-bottom: 29px;
}
.aboutus_content_para{
    font: normal 16px/30px 'Poppins';
    color: #645767;
    margin-bottom: 32px;
}
.aboutus_content_para span,.aboutus_content_para p{
    font: normal 16px/30px 'Poppins';
    color: #645767;
}
.aboutus_content_para:last-child:not(:nth-child(3)){
    margin-bottom: 0;
}
.know_more_btn{
    min-width: 167px;
    height: 54px;
    font: 600 16px/30px 'Poppins';
    background-color: #29A6ED;
    color: #FFFFFF;
    border-radius: 30px;
    border: 0;
    margin-bottom: 30px;
    margin: auto;
    display: block;
}
.aboutus_content_section_image{
    width: 100%;
    max-width: 500px;
}
.aboutus_content_section_image img{
    width: 100%;
    object-fit: cover;
}

@media (max-width: 991px){
    .aboutus_content{
        padding: 0 26px !important;
    }
    .aboutus_container .aboutus_image{
        display: none !important;
    }
    .aboutus_image figure .aboutus_image_photo{
        width: 100% !important;
        right: 50% !important;
        max-width: 400px !important;
    }
    .aboutus_image_photo_two{
        display: none !important;
    }
}
@media (max-width: 767px){
    /* .aboutus_content_section_image{
        width: calc(100% + 52px);
        margin: 0 -26px;
        max-width: none;
    } */
    .aboutus_container{
        padding-bottom: 20px;
    }
}
@media (max-width: 480px){
    .aboutus_content_section_image img{
        height: 400px;
    }
    .aboutus_container{
        padding-bottom: 40px;
    }
}
